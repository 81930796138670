/* eslint camelcase: 0 */
import { defineStore } from 'pinia';
import { sortDailyReads } from '@@/components/DailySnows/SortDailySnowUtils';
import { useUserStore } from '~/stores/User';

export const useDailyReadsStore = defineStore('dailyReads', {
  state: () => ({}),

  actions: {
    async fetchDailyReads(payload = {}) {
      const { $api } = useNuxtApp();
      const { sort, filter = 'All' } = payload;

      let { daily_reads } = await $api.get('/daily-reads');

      if (filter !== 'All') {
        daily_reads = daily_reads.filter((dr) => dr.categories.find((c) => c.name === filter));
      }

      const userStore = useUserStore();
      const location = userStore.getLocation();
      sortDailyReads(daily_reads, sort, location);

      return daily_reads;
    },

    async fetchDailyRead(payload = {}) {
      const { $api } = useNuxtApp();
      const {
        postId = null,
        slug = null,
        withContent = false,
      } = payload;

      const url = [
        '/daily-reads',
        slug ? `${slug}` : '',
        postId ? `post/${postId}` : '',
        withContent ? 'content' : '',
      ].filter((item) => item).join('/');

      const { daily_read } = await $api.get(url);

      return {
        article: daily_read,
      };
    },
  },
});
